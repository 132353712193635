import {useState, useEffect} from 'react';
import { ColorExtractor } from 'react-color-extractor'

const CacheImage = ({imageID, getColors}) => {
    const handleImageLoad = () => {
      // Add the image to the browser's cache
      new Image().src = src;
    };
    const [src, setSrc] = useState(`https://media.artblocks.io/thumb/${imageID}.png`)
    useEffect(()=>{
        setSrc(`https://media.artblocks.io/thumb/${imageID}.png`);
    },[imageID])
    const handleImageError = () => {
        setSrc(`https://media.artblocks.io/${imageID}.png`)
    }
  
    return (
        <ColorExtractor getColors={getColors}>
            <img className="nft-image" src={src} onLoad={handleImageLoad} onError={handleImageError} alt={"thumbnail"}/>
        </ColorExtractor>
    );
}

export default CacheImage