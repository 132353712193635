import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import Create from "./pages/Create";
import Explore from "./pages/Explore";
import ProjectDetail from "./pages/ProjectDetail"
import MyNFT from "./pages/MyNFT";
import NFTDetail from "./pages/NFTDetail";
import AboutUs from "./pages/AboutUs";

import { WagmiConfig } from "wagmi";
import { ConnectKitProvider } from "connectkit";
import client from "./lib/wagmi";


ReactDOM.render(
  <WagmiConfig client={client}>
      <ConnectKitProvider theme="auto" mode="light" options={{ initialChainId: 5 }}>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/create" element={<Create />} />
            <Route path="/explore" element={<Explore />} />
            <Route path="/detail" element={<NFTDetail />} />
            <Route path="/projectdetail" element={<ProjectDetail />} />
            <Route path="/myartblock" element={<MyNFT />} />
            <Route path="/about" element={<AboutUs />} />
          </Routes>
        </BrowserRouter>
      </ConnectKitProvider>
  </WagmiConfig>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
