import React, { useState, useEffect } from "react";
import "../styles/Hero.css";
import { useNavigate } from "react-router-dom";
import Header from "./Header";

const Hero = () => {
  let navigate = useNavigate();

  const goExplore = () => {
    navigate("/explore");
  };
  const goCreate = () => {
    navigate("/create");
  };

  return (
    <div id="hero">
      <Header />
      <h1 id="header-text-first"> Recreating and Preserving</h1>
      <h1 id="header-text-second"> On-chain Generative Art</h1>
      <h5 id="header-subtext">Artblocks Infinity is dedicated to leveraging decentralized subgraph technology to recreate and preserve the beauty of ArtBlocks' generative art.</h5>

      <div id="hero-buttons">
        <button id="explore" onClick={goExplore}>
          Explore Complete Collection
        </button>
        {/* <button id="create" onClick={goCreate}>Create</button> */}
      </div>
    </div>
  );
};

export default Hero;
