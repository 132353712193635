import React, {useState, useEffect, useMemo, useRef} from "react";
import { useLocation , useNavigate } from "react-router";
import { readContract } from "@wagmi/core";
import { createClient, configureChains, mainnet } from '@wagmi/core'
import { publicProvider } from '@wagmi/core/providers/public'
import ArtblocksJSON from "../lib/contracts/artblocks-contract.json";
import ArtblocksJSONV0 from "../lib/contracts/artblocks-contract-v0.json";
import { libraries, defaultCode } from "../lib/utils";
import Button from "../components/base/Button";
import {BiSave} from "react-icons/bi";
import {MdArrowBack} from "react-icons/md";
import "../styles/base/ProjectDetail.css"
import * as htmlToImage from 'html-to-image';
import html2canvas from 'html2canvas';

const ProjectDetail = () => {
  const { state } = useLocation();
  const [library, setLibrary] = useState("p5");
  const [code, setCode] = useState(defaultCode);
  const navigate = useNavigate();
  const checkCanvas = useRef(null);

  const libraryScript = useMemo(() => `<script src="${libraries[library]}"></script>`, [library]);

  const { provider, webSocketProvider } = configureChains(
    [mainnet],
    [publicProvider()],
  )
   
  const client = createClient({
    provider,
    webSocketProvider,
  })

  
  

  const SaveImagefunction = () => {
    // html2canvas(document.getElementById("liveArtBlock").contentWindow.document.body).then(function(canvas){
      if(library != "three"){
        const canvasTarget = document.getElementById("liveArtBlock").contentWindow.document.body;
        htmlToImage.toPng(canvasTarget)
        .then(function (dataUrl) {
          var link = document.createElement('a');
          link.download = `ArtBlock${state.collectionInfo.projectId * 1000000 + state.projectIndex}.png`;
          link.href = dataUrl;
          link.click();
        })
        .catch(function (error) {
          console.error('oops, something went wrong!', error);
        });
      }
    
      else{
        console.log("this is three js image")
      }
  //   const iframe = document.getElementById("liveArtBlock");
  // const canvas = iframe.contentWindow.document.getElementsByTagName("canvas")[0]; // Get the first canvas element
  // const imageDataUrl = canvas.toDataURL('image/png');

  // const link = document.createElement('a');
  // link.download = `ArtBlock${state.collectionInfo.projectId * 1000000 + state.projectIndex}.png`;
  // link.href = imageDataUrl;
  // link.click();

  // setTimeout(function() {
  //   document.body.appendChild(canvas)}, 10000);
  // })
  }

  useEffect(()=>{

    function removeSaveImagefunction() {
      console.log("======================")
      const iframeEle = document.getElementById("liveArtBlock");
      checkCanvas.current = setInterval(() => {
        console.log("----------------------")
        let canvasEles = iframeEle.contentWindow.document.getElementsByTagName("canvas");
        if (canvasEles.length > 0) {
          for (let i = 0; i < canvasEles.length; i++) {
            canvasEles[i].addEventListener("contextmenu", function(e) {
              e.preventDefault();
            });
          }
          clearInterval(checkCanvas.current);
        }
      }, 1000);
    };

    Promise.all([
      console.log("setting project library and code"),
      setLibrary(state.collectionInfo.scriptType),
      setCode(state.collectionInfo.script)
    ])
    .then(() => {
      console.log(window.innerHeight, window.innerWidth)
      const functionName =
      state.collectionInfo.contractAddress === "0x059edd72cd353df5106d2b9cc5ab83a52287ac3a"
      ? "showTokenHashes"
      : "tokenIdToHash";
      
      const abi =
      state.collectionInfo.contractAddress === "0x059edd72cd353df5106d2b9cc5ab83a52287ac3a"
      ? ArtblocksJSONV0.abi
      : ArtblocksJSON.abi;
      
      
      readContract({
        chainId: 1,
        // @ts-ignore
        address: state.collectionInfo.contractAddress,
        abi:abi,
        functionName:functionName,
        // @ts-ignore
        args: [state.collectionInfo.projectId * 1000000 + state.projectIndex],
      })
      .then((hash) => {
        const tokenData = `window.tokenData={"tokenId": "${state.projectIndex}", "hash": "${hash}", "hashes": ["${hash}"], "minter": "Owner"};`;
        // const wrappedCode = `<html><head>${libraryScript}</head><body style="margin: 0px"><script>${tokenData}${code}</script></body></html>`;
        // @ts-ignore
        const wrappedCode = `<html>
        <head>${libraryScript}
        </head>
        <body style="margin: 0px;align-items: center;display: flex;justify-content: center;">
        <script>
        ${tokenData}${code}
        </script>
        </body>
        </html>`;
        if(code!== defaultCode)
        document.getElementById("liveArtBlock").srcdoc = wrappedCode;
        removeSaveImagefunction()
      })
    })
    .catch((error) => {
      // Handle any errors here
      console.error("Error:", error);
    });
    return ()=>{
      console.log("Clean up function called")
      console.log("Interval ID:", checkCanvas.current);
      clearInterval(checkCanvas.current);
    }
  },[state, library, code])
  return (
    <div style={{width : `${window.innerWidth}px`, height : `${window.innerHeight}px`, overflow : "hidden"}}>
      <div className="projectDetailController">
        <Button
          width="50px"
          height="50px"
          child={
              <div id="button-child">
              <MdArrowBack size="28px" />
              </div>
          }
          onClick = {()=>{navigate(-1)}}
        ></Button>
        <Button
          width="50px"
          height="50px"
          child={
              <div id="button-child">
              <BiSave size="28px" />
              </div>
          }
          onClick = {()=>{SaveImagefunction()}}
        ></Button>
      </div>
      <iframe
        scrolling="no"
        style={{ overflow: "hidden", width : '100%', height : '100%'}}
        className="overflow-hidden w-full h-full col-span-3"
        id="liveArtBlock"
        title="liveArtBlock"
      />
    </div>
  );
};
export default ProjectDetail;
