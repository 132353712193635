import React, { useEffect, useState } from "react";
import "../styles/NFTCard.css";
import Card from "./base/Card";
import CacheImage from "./base/CacheImage";

const ProjectCard = ({ collectionInfo, projectIndex, onClick }) => {
  const [colors, setColors] = useState([]);
  const getColors = colors => {
    setColors(c => [...c, ...colors]);
  }
  
  return (
    <Card
      blurColor={colors[0]}
      onClick={onClick}
      child={<>
        <CacheImage imageID={collectionInfo.projectId * 1000000 + projectIndex} getColors={getColors}/>
        <div className="wrapper">
            <p className="name">#{projectIndex}</p>
        </div>
      </>}>

    </Card>
  );
};

export default ProjectCard;

