import React from "react";

const FavoriteTab = () => {
    return (
        <div>

        </div>
    )
}

export default FavoriteTab