import React, {useState} from "react"
import Header from "../components/Header"
import MyNftTab from "../components/base/MyNFTtabs/MyNftTab";
import FavoriteTab from "../components/base/MyNFTtabs/FavoriteTab";

const MyNFT = () => {
    const [activeTab, setActiveTab] = useState("mycollection");
    const [collectionDetail, setCollectionDetail] = useState({})
    const handleFavTab = () => {
        setActiveTab("favorite")
    }
    const handleMyNftTab = () => {
        setActiveTab("mycollection")
    }
    return(
        <>
            <Header />
            <div className="Tabs" style={{marginTop : "100px"}}>
                <ul className="nav">
                    <li className={activeTab === "mycollection" ? "active" : ""} onClick={handleMyNftTab}>My NFT Collections</li>
                    <li className={activeTab === "favorite" ? "active" : ""} onClick={handleFavTab}>Favorite</li>
                </ul>
                <div className="outlet">
                    {activeTab === "mycollection" && collectionDetail ? <MyNftTab item={collectionDetail}/>  : <></>}
                    {activeTab === "favorite" ? <FavoriteTab item={collectionDetail}/> : <></>}
                </div>
            </div>
        </>
    )
}

export default MyNFT