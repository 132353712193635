import React, { useEffect, useState } from "react";
import "../styles/NFTCard.css";
import Card from "./base/Card";
import CacheImage from "./base/CacheImage";
import { Colors } from "../constants/Colors";

const CollectionCard = ({ collectionInfo, onClick }) => {
  const [colors, setColors] = useState([]);
  const getColors = colors => {
    setColors(c => [...c, ...colors]);
  }
  
  return (
    <Card
      blurColor={colors[0]}
      onClick={onClick}
      child={<>
        <CacheImage imageID={collectionInfo.projectId * 1000000} getColors={getColors}/>
        <div className="wrapper">
          <div className="info-container">
            <p className="owner">{collectionInfo.artistName}</p>
            <p className="name">{collectionInfo.name}</p>
          </div>

          <div className="price-container">
            <p className="price-label">Project Id</p>
            <p className="price">
              {" "}
              {collectionInfo.projectId}
            </p>
          </div>
        </div>
        <div className="buttons">
          {/* <Button color={Colors.buttons.primary} textContent="Buy Now" onClick={onClick} /> */}
          <div className="like-container">
            <p className="like-count">{collectionInfo.invocations}/{collectionInfo.maxInvocations} Minted</p>
          </div>
        </div>
      </>}>

    </Card>
  );
};

export default CollectionCard;

