import React, { useState, useEffect, createRef } from "react";
import Header from "../components/Header";
import { useNavigate } from "react-router-dom";
import { useLocation, Navigate } from "react-router";
import NFTDetailCard from "../components/base/NFTDetailCard";
import "../styles/base/Tab/NFTDetail.css";
import Tab from "../components/base/Tab"



const NFTDetail = () => {

  const [colors, setColors] = useState([]);

  const getColors = (colors) => {
    setColors((c) => [...c, ...colors]);
  };

  const navigate = useNavigate();

  const { state } = useLocation();

  useEffect(() => {
    setColors([]);
  }, [state]);


  return (
    <div>
      <Header />
      <div className="nft-detail-card-wrapper">
        <NFTDetailCard
          width={"100%"}
          blurColor={colors[0]}
          child={
            //Detail Content
            <Tab item={state.item} getColors={getColors}></Tab>
          }
        />
        
      </div>

    </div>
  );
};

export default NFTDetail;
