import React, { useState, useEffect } from "react";
import CollectionCard from "./CollectionCard";
import ProjectCard from "./ProjectCard";
import "../styles/CardList.css";
import { useNavigate } from "react-router-dom";

const CardList = ({ collections, Directiontype = "horizontal", type, projectCount, collectionInfo }) => {
  const [visibleCollections, setVisibleCollections] = useState(20);
  const [visibleProjects, setVisibleProjects] = useState(20);
  const navigate = useNavigate();

  const handleScroll = () => {
    const windowHeight = "innerHeight" in window ? window.innerHeight : document.documentElement.offsetHeight;
    const body = document.body;
    const html = document.documentElement;
    const docHeight = Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight);
    const windowBottom = windowHeight + window.pageYOffset;

    if (windowBottom >= docHeight - 100) {
      if (type === "collection" && visibleCollections < collections.length) {
        console.log("=============", visibleCollections, collections.length)
        setVisibleCollections(visibleCollections + 20);
      } else if (type === "project" && visibleProjects < projectCount) {
        console.log("*********", visibleProjects, projectCount)
        setVisibleProjects(visibleProjects + 20);
      }
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [type ==="collection" && collections.length, visibleProjects, visibleCollections]);

  return (
    <div id="card-list" style={{ flexDirection: Directiontype === "horizontal" ? "row" : "column" }}>
      {type === "collection" && collections.slice(0, visibleCollections).map((collection, index) => (
        <CollectionCard
          collectionInfo={collection}
          key={index}
          onClick={() => navigate("/detail", { state: { item: collection } })}
        />
      ))}
      {type === "project" && [...Array(projectCount < 20 ? projectCount : visibleProjects)].map((v, index) => (
        <ProjectCard
          collectionInfo={collectionInfo}
          projectIndex={index}
          key={index}
          onClick={() => navigate("/projectdetail", { state: { collectionInfo: collectionInfo, projectIndex: index } })}
        />
      ))}
    </div>
  );
};

export default CardList;
