import React, {useState, useEffect, useMemo} from "react";

import CodeMirror from "@uiw/react-codemirror";
import { dracula } from "@uiw/codemirror-theme-dracula";
import { javascript } from "@codemirror/lang-javascript";
import { EditorView } from "@codemirror/view";
import { libraries, defaultCode } from "../lib/utils";
import { ethers } from "ethers";
import Header from "../components/Header";
import CreateCommands from "../components/base/CreateCommands";
import Modal from 'react-modal';
import TextInput from "../components/base/TextInput";
import Select from "../components/base/Select";
import "../styles/Create.css"
import { minify } from "terser";

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
    borderRadius: '30px',
    backdropFilter: 'blur(50px)',
    color : 'white',
    background: 'rgb(0, 0, 0)',
  },
};

const Create = () => {
  const [collection, setCollection] = useState();
  const [code, setCode] = useState(defaultCode);
  const [tokenId, setTokenId] = useState("0");
  const [minter, setMinter] = useState("Owner");
  const [library, setLibrary] = useState("p5");
  const [autoRefresh, setAutoRefresh] = useState(true);
  const [wordWrap, setWordWrap] = useState(true);
  const [hash, setHash] = useState(ethers.utils.hexlify(ethers.utils.randomBytes(32)));
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [script, setScript] = useState("")

  //collection information
  const [formData, setFormData] = useState({
    contractType: "Learn",
    name: "",
    symbol: "",
    description: "",
    artist: "",
    defaultImage: "",
    maxSupply: 0,
    price: 0,
    _library: "p5",
    code: "",
  });

  useEffect(()=>{
    generateOutput(hash)
  }, [code])
  const libraryScript = useMemo(() => `<script src="${libraries[library]}"></script>`, [library]);

  const generateOutput = (_hash) => {
    if (document) {
      const tokenData = `window.tokenData={"tokenId": "${tokenId}", "hash": "${_hash}", "hashes": ["${_hash}"], "minter": "${minter}"};`;
      const wrappedCode = `<html><head>${libraryScript}</head><body style="margin: 0px;display: flex;justify-content: center;"><script>${tokenData}${code}</script></body></html>`;
      // @ts-ignore
      document.getElementById("liveArtBlock").srcdoc = wrappedCode;
    }
  };

  const handleRun = (event) => {
    event.preventDefault();
    generateOutput(hash);
  };

  const updateHash = (_hash) => {
    setHash(_hash);
    if (autoRefresh) {
      generateOutput(_hash);
    }
  };

  const openModal = (event) => {
    event.preventDefault();
    minify(code).then((result) => {
      setFormData({...formData, 'code' : result.code})
    })
    .then(()=>
      setIsOpen(true)
    )
  }

  const closeModal = () => {
    setIsOpen(false)
  }

  const handleCreate = (e) => {
    e.preventDefault();

  }

  return (
    <>
      <Header />
      <CreateCommands 
        collection = {collection}
        hash = {hash}
        updateHash = {updateHash}
        minter = {minter}
        setMinter = {setMinter}
        handleRun = {handleRun}
        autoRefresh = {autoRefresh}
        setAutoRefresh = {setAutoRefresh}
        wordWrap = {wordWrap}
        setWordWrap = {setWordWrap}
        library = {library}
        setLibrary = {setLibrary}
        handleCreate = {openModal}
      />
      <div className="newNFT_container">
        <div style={{width : '60%', textAlign : "left", fontSize : "18px"}}>
          <CodeMirror
            value={code}
            height="800px"
            theme={dracula}
            extensions={[...(wordWrap ? [EditorView.lineWrapping] : []), javascript()]}
            onChange={(code) => setCode(code)}
          />
        </div>
        <div style={{width : '40%'}}>
          <iframe
            title="ArtBlock"
            scrolling="no"
            style={{ overflow: "hidden", width : '100%', height : '100%'}}
            className="overflow-hidden w-full h-full col-span-3"
            id="liveArtBlock"
          />
        </div>
      </div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <div className="modalMain">
          <h2>Create New Collection</h2>
            <div className="row">
              <div className="col-4">
                <label htmlFor="Name">Name</label>
                <TextInput width={100} height={50} placeholder={"Collection Name"} type={"text"} changefilter={(value)=>{setFormData({...formData, 'name' : value})}}/>
              </div>
              <div className="col-4">
                <label htmlFor="Name">Symbol</label>
                <TextInput width={100} height={50} placeholder={"Collection Symbol"} type={"text"} changefilter={(value)=>{setFormData({...formData, 'symbol' : value})}}/>
              </div>
            </div>
            <div className="row">
              <div className="col-4">
                <label htmlFor="Name">Artist</label>
                <TextInput width={100} height={50} placeholder={"Collection Artist"} type={"text"} changefilter={(value)=>(setFormData({...formData, 'symbol' : value}))}/>
              </div>
              <div className="col-4">
                <label htmlFor="Name">Default collection image (URL / IPFS)</label>
                <TextInput width={100} height={50} placeholder={"Collection Default Image"} type={"text"} changefilter={(value)=>(setFormData({...formData, 'defaultImage' : value}))}/>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <label htmlFor="Name">Description</label>
                <textarea className="collectionDescription" placeholder={"Collection description"} type={"text"}></textarea>
              </div>
            </div>
            <div className="row">
              <div className="col-4">
                <label htmlFor="Name">Max Supply</label>
                <TextInput width={100} height={50} value = {formData.maxSupply} placeholder={"0"} type={"text"} changefilter={(value)=>(setFormData({...formData, 'maxSupply' : value}))}/>
              </div>
              <div className="col-4">
                <label htmlFor="Name">Price</label>
                <TextInput width={100} height={50} placeholder={"Collection Price"} type={"text"} changefilter={(value)=>(setFormData({...formData, 'price' : value}))}/>
              </div>
            </div>
            <div className="row">
              <div className="col-4">
                <label htmlFor="collectionType">Type</label>
                <Select
                    initValue = {formData.contractType}
                    items = {['Learn', 'Certificate']}
                    onChange = {(value)=>{setFormData({...formData, 'contractType' : value})}}
                />
              </div>
              <div className="col-4">
                <label htmlFor="collectionType">Library</label>
                <Select
                    initValue = {formData._library}
                    items = {["P5", "Three"]}
                    onChange = {(value)=>{setFormData({...formData, '_library' : value})}}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <label htmlFor="Name">Code</label>
                <textarea className="collectionDescription" 
                  placeholder={"Collection Code"} 
                  type={"text"} 
                  value={formData.code}
                  onChange={(value) => {setFormData({...formData, 'code' : value})}}
                />
              </div>
            </div>
            <div className="row" style={{padding : '50px', justifyContent : 'center'}}>
              <div style={{width : '80%', display : "flex", justifyContent : 'space-around'}}>
                <button id="create" onClick={closeModal}>Close</button>
                <button id="explore" onClick={(e)=>{handleCreate(e)}}>Create</button>
              </div>
            </div>
        </div>
      </Modal>
    </>
  );
};

export default Create;
