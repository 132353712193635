import React, {useState, useEffect} from "react";
import "../../styles/base/Tab.css";
import DetailTab from "./NFTDetailTabs/DetailTab";
import ItemsTab from "./NFTDetailTabs/ItemsTab";
import NewExpTab from "./NFTDetailTabs/NewExpTab";
import { getABCollection } from "../../lib/artblocks";


const Tabs = ({item, getColors}) => {
    const [activeTab, setActiveTab] = useState("detail");
    const [collectionDetail, setCollectionDetail] = useState({})
    const handleDetailTab = () => {
        setActiveTab("detail")
    }
    const handleItemsTab = () => {
        setActiveTab("items")
    }
    const handleNewExpTab = () => {
        setActiveTab("newexp")
    }
    useEffect(()=>{
        const collection = localStorage.getItem(item.id);
        if(collection){
            setCollectionDetail(JSON.parse(collection))
        }
        else{
        (async() => {
            console.log("no data, fetching now")
            let collection = await getABCollection(item.id);
            localStorage.setItem(item.id, JSON.stringify(collection))
            setCollectionDetail(collection)
            })();
        }
    },[])
    return (
        <div className="Tabs">
            <ul className="nav">
                <li className={activeTab === "detail" ? "active" : ""} onClick={handleDetailTab}>Collection Detail</li>
                <li className={activeTab === "items" ? "active" : ""} onClick={handleItemsTab}>All Items</li>
                <li className={activeTab === "newexp" ? "active" : ""} onClick={handleNewExpTab}>New Experience</li>
            </ul>
            <div className="outlet">
                {activeTab === "detail" && collectionDetail ? <DetailTab item={collectionDetail} getColors={getColors}/>  : <></>}
                {activeTab === "items" ? <ItemsTab item={collectionDetail}/> : <></>}
                {activeTab === "newexp" ? <NewExpTab item={collectionDetail}/> : <></>}
            </div>
        </div>
    );
};
export default Tabs;