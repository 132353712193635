import {useState, useEffect, useMemo} from "react";
import CardList from "../components/CardList";
import '../styles/Explore.css';
import Header from "../components/Header";
import Search from "../components/Search";
import { getABCollections } from "../lib/artblocks.ts";
import Select from "../components/base/Select";
const Explore = () => {

  const [searchFilter, setSearchFilter] = useState('')
  const [statusFilter, setStatusFilter] = useState("All")
  const [initCollections, setInitCollections] = useState([])
  const [sortFilter, setSortFilter] = useState("Sort by Collection Id, ASC")
  // const [filteredCollections, setFilteredCollections] = useState([])

  const SortByType = (sortArray, sortType) => {
    if(sortType === "Sort by Collection Id, ASC"){
      return sortArray.sort((a, b) => a['projectId'] > b['projectId'] ? 1 : -1)
    }
    if(sortType === "Sort by Collection Id, DESC"){
      return sortArray.sort((a, b) => a['projectId'] < b['projectId'] ? 1 : -1)
    }
    if(sortType === "Sort by Collection Name, ASC"){
      return sortArray.sort((a, b) => a['name'] > b['name'] ? 1 : -1)
    }
    if(sortType === "Sort by Collection Name, DESC"){
      return sortArray.sort((a, b) => a['name'] < b['name'] ? 1 : -1)
    }
    if(sortType === "Sort by Artist Name, ASC"){
      return sortArray.sort((a, b) => a['artistName'] > b['artistName'] ? 1 : -1)
    }
    if(sortType === "Sort by Artist Name, DESC"){
      return sortArray.sort((a, b) => a['artistName'] < b['artistName'] ? 1 : -1)
    }
  }

  useEffect(()=>{
    // const savedCollections = localStorage.getItem('collectionsData');
    // if(savedCollections){
    //   setInitCollections(JSON.parse(savedCollections))
    //   console.log(JSON.parse(savedCollections))
    // }
    // else
    (async() => {
      let collections = await getABCollections();
      setInitCollections(collections)
      await console.log(collections)
    })();
  },[]);

  const filteredCollections = useMemo(() => {
    let filteredCollections = [];
    if (initCollections) {
      if (statusFilter === "All"){
        filteredCollections = SortByType(initCollections.filter((collection)=> collection.complete || (!collection.complete && collection.active)), sortFilter);
      }
      else if (statusFilter === "Completed") {
        filteredCollections = SortByType(initCollections.filter((collection) => collection.complete), sortFilter);
      } else {
        filteredCollections = initCollections.filter((collection) => !collection.complete && collection.active);
        if (statusFilter === "Upcoming") {
          filteredCollections = SortByType(filteredCollections.filter(
            (collection) =>
              collection.mintingDate * 1000 > Date.now() || (!collection.mintingDate && !collection.activatedAt)
          ), sortFilter);
        } else if (statusFilter === "Open") {
          filteredCollections = SortByType(filteredCollections.filter(
            (collection) =>
              !collection.paused &&
              (collection.mintingDate * 1000 < Date.now() || (!collection.mintingDate && collection.activatedAt))
          ), sortFilter);
        } else if (statusFilter === "Paused") {
          filteredCollections = SortByType(filteredCollections.filter(
            (collection) =>
              collection.paused &&
              ((collection.mintingDate && collection.mintingDate * 1000 < Date.now()) ||
                (collection.activatedAt && collection.activatedAt * 1000 < Date.now()))
          ), sortFilter);
        }
      }

      if (searchFilter) {
        console.log(filteredCollections)
        filteredCollections = SortByType(filteredCollections
          .filter(
          (collection) =>
            collection.name.toLowerCase().includes(searchFilter.toLowerCase()) ||
            collection.artistName.toLowerCase().includes(searchFilter.toLowerCase()) ||
            collection.projectId.toString().includes(searchFilter.toLowerCase())
        )
        , 
        sortFilter);
      }
    }

    return filteredCollections;
  }, [initCollections, statusFilter, searchFilter, sortFilter]);

  
  const changefilter = (filterValue) => {
    setSearchFilter(filterValue);
  }
  return (
    <div id="explore">
      <Header/>
      <div style={{paddingTop : "10%"}}>
        <Search changefilter={changefilter}/>
        <div style={{display : 'flex', flexDirection : 'row', justifyContent : 'space-around', padding : '50px'}}>
          <div style={{width : '20%'}}>
            <Select 
              initValue = {statusFilter}
              items = {['All', 'Completed', 'Upcoming', 'Open', 'Paused']}
              onChange = {(value)=>{setStatusFilter(value)}}
            />
          </div>
          <div style={{width : '20%'}}>
            <Select 
              initValue = {sortFilter}
              items = {
                ["Sort by Collection Id, ASC", 
                  "Sort by Collection Id, DESC", 
                  "Sort by Collection Name, ASC", 
                  "Sort by Collection Name, DESC",
                  "Sort by Artist Name, ASC",
                  "Sort by Artist Name, DESC"
                ]}
              onChange = {(value)=>{setSortFilter(value)}}
            />
          </div>
        </div>
      </div>
      <div id="list-container">
        <CardList collections={filteredCollections} type={"collection"}/>
      </div>
    </div>
  );
};

export default Explore;
