import { Link } from "react-router-dom";
import { ConnectKitButton } from "connectkit";

const Header = () => {


    return (
        <div id="header">
        <Link to='/' id='logo'>Artblocks Infinity</Link>

        <div id="link-containers">
          <a href="about">About US</a>
          {/* <a href="contact">Contact US</a>
          <a href="myartblock">My NFT</a> */}
          <ConnectKitButton showBalance={true} />
          {/* <button id="connect-wallet" onClick={handleWallet} >{!account ? 'Connect Wallet' : account}</button> */}
        </div>
      </div>
    );
}

export default Header;