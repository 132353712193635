import React, { useState, useEffect, createRef } from "react";
import Header from "../components/Header";
import "../styles/AboutUs.css"
const AboutUs = () => {

  return (
    <div>
      <Header />
      <div className="about_content">
        <p>We leverage decentralized subgraph technology to recreate and preserve the beauty of ArtBlocks' generative art.</p>
        <p>When we use a subgraph to read and generate ArtBlocks on-chain generative art, we deploy a custom indexing and querying endpoint that allows you to efficiently access and retrieve the necessary blockchain data to recreate the artwork.</p>
        <p>ArtBlocks generative art is created by running code on the blockchain, which generates unique outputs based on the input parameters.</p>
        <p>These parameters are stored on-chain, making it possible for you to recreate the artwork at any time by rerunning the code with the same input.</p>
        <p>By deploying a subgraph, we can create a custom index of the on-chain data that defines the input parameters for each ArtBlocks artwork, and make it accessible through a custom API endpoint.</p> 
        <p>This approach makes it easier for us to efficiently query and extract the necessary data to regenerate the artwork, and provides a more flexible and customizable way to work with the ArtBlocks generative art data.</p>
        <p>Using a subgraph to read and generate ArtBlocks on-chain generative art can provide several benefits for us, such as faster query times, reduced network congestion, and the ability to access more granular and specific data points.</p> 
        <p>It also enables us to build more flexible and customizable applications that can regenerate ArtBlocks generative art on demand, allowing for new use cases and possibilities for digital art.</p>
      </div>

    </div>
  );
};

export default AboutUs;
