import { ethers } from "ethers";
import {FiPlay} from "react-icons/fi"
import {FiRefreshCcw} from "react-icons/fi"
import Checkbox from "../../components/base/Checkbox";
import Select from "../../components/base/Select"
import Button from "./Button";

const EditorCommands = ({
  collection,
  hash,
  updateHash,
  minter,
  setMinter,
  handleRun,
  autoRefresh,
  setAutoRefresh,
  wordWrap,
  setWordWrap,
  library,
  setLibrary,
  handleCreate,
}) => {

  return (
    <>
        <div className="flex justify-between" style={{paddingTop : '100px'}}>
            <div className="flex-row w-full pr-3" style={{display : "flex", justifyContent : "space-around", alignItems : 'center'}}>
                <Select 
                    initValue = {'Library'}
                    items = {['p5', 'three']}
                    onChange = {setLibrary}
                />
                <input
                    type="text"
                    name="hash"
                    id="hash"
                    value={hash}
                    onChange={(event) => updateHash(event.target.value)}
                />
                <Button
                    type="button"
                    width="50px"
                    height="50px"
                    onClick={(e) => updateHash(ethers.utils.hexlify(ethers.utils.randomBytes(32)))}
                    child={
                        <FiRefreshCcw aria-hidden="true" size={'28px'}/>
                    }
                />
                <Button 
                    type="button" 
                    onClick={handleRun}
                    width="50px"
                    height="50px"
                    child={
                        <FiPlay
                        aria-hidden="true"
                        size={'28px'}
                        />
                    }
                />
                <Button  
                    type="button" 
                    textContent="Create new collection" 
                    onClick={(event) => handleCreate(event)} 
                    width="200px"
                    height="50px"
                />
                {collection?.type === "Certificate" && (
                <div className="flex text-sm mt-3 space-x-3">
                    <label htmlFor="minter" className="mr-2 flex font-medium text-gray-700 items-center">
                    Minter
                    </label>
                    <div>
                    <input
                        type="text"
                        name="minter"
                        id="minter"
                        value={minter}
                        onChange={(event) => setMinter(event.target.value)}
                        className="inline w-full h-7 text-sm rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                    />
                    </div>
                </div>
                )}
            </div>
            <div className="flex text-sm mt-3 justify-between" style={{display : 'flex', justifyContent : 'space-around', paddingTop : '20px'}}>
                <Checkbox 
                    name={"Auto-refresh"}
                    onChange={(e) => setAutoRefresh(e.target.checked)}
                    checked={autoRefresh}
                />
                <Checkbox 
                    name={"Word-wrap"}
                    onChange={(e) => setWordWrap(e.target.checked)}
                    checked={wordWrap}
                />
            </div>
        </div>
    </>
  );
};

export default EditorCommands;
