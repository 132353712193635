import React, { useEffect } from "react";
import CardList from "../../CardList";

const ItemsTab = ({item}) => {
  return (
    <div className="ItemsTab">
      <p className="name">{item.invocations} Minted</p>
      <div id="list-container">
        <CardList projectCount = {item.invocations} type={"project"} collectionInfo={item}/>
      </div>
    </div>
  );
};
export default ItemsTab;
