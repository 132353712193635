import React from "react";
import { ethers } from "ethers";
import {FiRefreshCcw} from "react-icons/fi"
import {FiPlay} from "react-icons/fi"
import {BsFullscreen, BsHeart} from "react-icons/bs"
import {BiSave} from "react-icons/bi";
import '../../styles/base/TextInput.css';

import Button from "./Button";
const EditorCommands = ({
    hash,
    updateHash,
    handleRun,
    setPopupWinHeight,
    setPopupWinWidth,
    showPopup,
    saveAsImage,
    likeNft
}) => {
    
    return (
        <div className="EditorCommands">
            <p htmlFor="hash" className="name">
                Hash
            </p>
            <input
                type="text"
                name="hash"
                id="hash"
                value={hash}
                onChange={(event) => updateHash(event.target.value)}
            />
            <Button
                type="button"
                width="50px"
                height="50px"
                onClick={(e) => updateHash(ethers.utils.hexlify(ethers.utils.randomBytes(32)))}
                child={
                    <FiRefreshCcw aria-hidden="true" size={'28px'}/>
                }
            />
            <Button 
                type="button" 
                onClick={handleRun}
                width="50px"
                height="50px"
                child={
                    <FiPlay
                    aria-hidden="true"
                    size={'28px'}
                    />
                }
            />
            <div
                className="sizeSet-container"
                style={{
                background: `radial-gradient(
                            circle,
                            rgba(255, 255, 255, 0.05) 0%,
                            rgba(48,118,234,0.2) 0%,
                            rgba(255, 255, 255, 0.05) 70%
                        )`,
                }}
            >
                <input className="sizeSet-input" placeholder='Width' type='text' onChange={(e)=>{setPopupWinWidth(e.target.value)}}/>
            </div>
            <div
                className="sizeSet-container"
                style={{
                background: `radial-gradient(
                            circle,
                            rgba(255, 255, 255, 0.05) 0%,
                            rgba(48,118,234,0.2) 0%,
                            rgba(255, 255, 255, 0.05) 70%
                        )`,
                }}
            >
                <input className="sizeSet-input" placeholder='Height' type='text' onChange={(e)=>{setPopupWinHeight(e.target.value)}} />
            </div>
            <Button 
                type="button" 
                onClick={showPopup}
                width="50px"
                height="50px"
                child={
                    <BsFullscreen
                    aria-hidden="true"
                    size={'28px'}
                    />
                }
            />
            <Button 
                type="button" 
                onClick={saveAsImage}
                width="50px"
                height="50px"
                child={
                    <BiSave
                    aria-hidden="true"
                    size={'28px'}
                    />
                }
            />
            <Button 
                type="button" 
                onClick={likeNft}
                width="50px"
                height="50px"
                child={
                    <BsHeart
                    aria-hidden="true"
                    size={'28px'}
                    />
                }
            />
        </div>
    );
};
export default EditorCommands;
