import React from "react";
import '../../styles/base/Checkbox.css';

const Checkbox = ({name, onChange, checked}) => {
  return (
    <label className="container">
      <p>{name}</p>
      <input type="checkbox" onChange={onChange} checked={checked}/>
      <span className="checkmark"></span>
    </label>
  );
};

export default Checkbox;
