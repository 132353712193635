import {useEffect, useState}from "react";
import Hero from "../components/Hero";
import "../styles/Home.css";
import CardList from "../components/CardList";
import { getABCollections } from "../lib/artblocks.ts";

const Home = () => {
  const [upcomingCollections, setUpcomingCollections] = useState([]);
  const [openCollections, setOpenCollections] = useState([]);
  useEffect(()=>{
    // const savedCollections = localStorage.getItem('collectionsData');
    // if(savedCollections){
    //   let incompletedcollections = (JSON.parse(savedCollections)).filter((collection) => !collection.complete && collection.active)
    //   setUpcomingCollections(incompletedcollections.filter((collection) => collection.mintingDate * 1000 > Date.now() || (!collection.mintingDate && !collection.activatedAt)))
    //   setOpenCollections(incompletedcollections.filter((collection) => !collection.paused && (collection.mintingDate * 1000 < Date.now() || (!collection.mintingDate && collection.activatedAt))))
    // }
    // else
    (async() => {
      let collections = await getABCollections();
      // localStorage.setItem("collectionsData", JSON.stringify(collections))
      let incompletedcollections = collections.filter((collection) => !collection.complete && collection.active)
      setUpcomingCollections(incompletedcollections.filter((collection) => collection.paused && (collection.mintingDate * 1000 > Date.now() || (!collection.mintingDate && !collection.activatedAt))))
      setOpenCollections(incompletedcollections.filter((collection) => !collection.paused && (collection.mintingDate * 1000 < Date.now() || (!collection.mintingDate && collection.activatedAt))))
    })();
  },[])

  return (
    <div id="home">
      <Hero/>
      <p id="card-list-header-text"> Upcoming </p>
      <div id="list-container">
        <CardList collections={upcomingCollections} type={"collection"}/>
      </div>
      <p id="card-list-header-text"> Open </p>
      <div id="list-container">
        <CardList collections={openCollections} type={"collection"}/>
      </div>
    </div>
  );
};

export default Home;
