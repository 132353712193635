import React, {useState, useMemo, useEffect} from "react";
import { ColorExtractor } from "react-color-extractor";
import Button from "../../base/Button";
import {AiFillCodeSandboxCircle} from "react-icons/ai"
import {ImEarth} from "react-icons/im"

const DetailTab = ({item, getColors}) => {
    const [src, setSrc] = useState(null);

    const handleImageError = () => {
        setSrc(`https://media.artblocks.io/${item.projectId * 1000000}.png`);
    };

    const date = useMemo(() => {
        if (item) {
            return item.completedAt || item.mintingDate || item.activatedAt;
        }
    }, [item]);

    // Set the image source only if the item has a projectId
    useEffect(() => {
        if (item && item.projectId) {
            setSrc(`https://media.artblocks.io/thumb/${item.projectId * 1000000}.png`);
        }
    }, [item]);

    return (
        <div className="DetailTab">
            {item.name && (
                <>
            <div className='detail-title'>
                <p className="collection"> {item.name} </p>
                <p className="name"> {item.artistName} </p>
            </div>
            <div className="detail-content">
                <div style={{width : '40%', objectFit : 'cover'}}>
                    {src && (
                    <ColorExtractor getColors={getColors}>
                        <img className="detail-image" src={src}  onError={handleImageError} alt={`${item.projectId} thumbnail`}/>
                    </ColorExtractor>
                    )}
                    <div className="detail-controls">
                        <Button
                            width="70%"
                            height="50px"
                            child={
                                <div id="button-child">
                                <p id="price">#{item.projectId}</p>
                                </div>
                            }
                        ></Button>
                        <a href={`${item.website}`} className="websiteLink">
                        <Button
                            width="50px"
                            height="50px"
                            child={
                                <div id="button-child">
                                <ImEarth size="28px" />
                                </div>
                            }
                        ></Button>
                        </a>
                    </div>
                    
                    <div>
                        <a href={`https://etherscan.io/address/${item.contractAddress}`} className="websiteLink">
                            <Button
                                width="100%"
                                height="50px"
                                child={
                                    <div id="button-child">
                                    <AiFillCodeSandboxCircle size="28px" />
                                    <p id="price">{item.contractAddress}</p>
                                    </div>
                                }
                            ></Button>
                        </a>
                    </div>
                    <div className="detail-controls">
                        <p className="collection"> {item.invocations} / {item.maxInvocations} Minted</p>
                        {date && <p className="collection">{new Date(date * 1000).toUTCString()}</p>} 
                    </div>
                </div>

                <div className="detail-info" style={{}}>
                    <p className="description" > {item.description} </p>
                </div>
            </div>
            </>
            )}
        </div>
    );
};
export default DetailTab;
