import React, {useState, useEffect, useMemo}from "react";
import EditorCommands from "../EditorCommands";
import CodeMirror from "@uiw/react-codemirror";
import { dracula } from "@uiw/codemirror-theme-dracula";
import { javascript } from "@codemirror/lang-javascript";
import { EditorView } from "@codemirror/view";
import { libraries, defaultCode } from "../../../lib/utils.ts";
import { useCodArt } from "../../../components/collections-context.tsx";
import { ethers } from "ethers";
// @ts-ignore
import prettier from "prettier/esm/standalone.mjs";
// @ts-ignore
import parserBabel from "prettier/esm/parser-babel.mjs";
import { getABCollection } from "../../../lib/artblocks";
import * as htmlToImage from 'html-to-image';

const NewExpTab = ({item}) => {
  const { cACollections } = useCodArt();
  const [collection, setCollection] = useState();
  const [code, setCode] = useState("");
  const [minter, setMinter] = useState("Owner");
  const [library, setLibrary] = useState("p5");
  const [autoRefresh, setAutoRefresh] = useState(true);
  const [wordWrap, setWordWrap] = useState(true);
  const [popupHeight, setPopupWinHeight] = useState();
  const [popupWidth, setPopupWinWidth] = useState();
  const [hash, setHash] = useState(ethers.utils.hexlify(ethers.utils.randomBytes(32)));

  const projectId = useMemo(() => (item.id ? (item.id) : undefined), [item.id]);
  const isAB = useMemo(() => (projectId?.includes("-") ? true : false), [projectId]);
  const libraryScript = useMemo(() => `<script src="${libraries[library]}"></script>`, [library]);
  const [aBCollection, setABCollection] = useState();
  useEffect(()=>{
    const collection = localStorage.getItem(item.id);
    if(collection){
      setABCollection(JSON.parse(collection))
    }
    else{
    (async() => {
        console.log("no data, fetching now")
    let collection = await getABCollection(item.id);
    localStorage.setItem(item.id, JSON.stringify(collection))
    setABCollection(collection)
    })();
    }
},[])

  const generateOutput = (_hash) => {
    if (document) {
      const tokenData = `window.tokenData={"tokenId": "0", "hash": "${_hash}", "hashes": ["${_hash}"], "minter": "${minter}"};`;
      const wrappedCode = `<html><head>${libraryScript}</head><body style="margin: 0px;display: flex;justify-content: center;"><script>${tokenData}${code}</script></body></html>`;
      // @ts-ignore
      document.getElementById("canvasIframe").srcdoc = wrappedCode;
    }
  };

  const handleRun = (event) => {
    event.preventDefault();
    generateOutput(hash);
  };

  const updateHash = (_hash) => {
    setHash(_hash);
    if (autoRefresh) {
      generateOutput(_hash);
    }
  };

  useEffect(() => {
    setCollection(undefined);
    let formattedCode = defaultCode;
    if (projectId) {
      let _collection;

      if (isAB && aBCollection) {
        _collection = aBCollection;
        formattedCode = _collection.script;
        if (_collection?.scriptType === "p5" || _collection?.scriptType === "three") {
          setLibrary(_collection?.scriptType);
        }
      } else if (!isAB) {
        const _cACollection = cACollections.find((c) => c._address === projectId);
        if (_cACollection) {
          _collection = _cACollection;
          formattedCode = _collection.code;
        }
      }
      if (_collection) {
        setCollection(_collection);

        try {
          formattedCode = prettier.format(formattedCode, {
            parser: "babel",
            plugins: [parserBabel],
          });
        } catch {}
      }
    }
    setCode(formattedCode);
  }, [projectId, aBCollection, cACollections]);

  const showPopup = () => {
    let PHeight, PWidth;
    if(!popupWidth || popupWidth == "" || popupWidth == 0){
      console.log(window.screen.width)
      PWidth = window.screen.width
    }
    else PWidth = popupWidth
    if(!popupHeight || popupHeight == "" || popupHeight == 0){
      console.log(window.screen.height)
      PHeight = window.screen.height
    }
    else PHeight = popupHeight
    const tokenData = `window.tokenData={"tokenId": "0", "hash": "${hash}", "hashes": ["${hash}"], "minter": "${minter}"};`;
    const wrappedCode = `<html><head>${libraryScript}</head><body style="margin: 0px;display: flex;justify-content: center;"><script>${tokenData}${code}</script></body></html>`;
    const top = (window.innerHeight - PWidth)/2
    const left = (window.innerWidth - PHeight)/2
    var win = window.open("", "", `width=${PWidth}, height=${PHeight}, top = ${top}, left = ${left}, popup=true, directories=no, titlebar=no, toolbar=no, location=no, status=no, menubar=no, scrollbars=no, resizable=no`);
    win.document.write(`<iframe width="${PWidth}" height="${PHeight - 150}" frameborder="0" id="token_frame"></iframe>`)
    win.document.body.style.backgroundColor = "black"
    win.document.body.style.display = "flex"
    win.document.body.style.justifyContent = "center"
    win.document.getElementById('token_frame').srcdoc = wrappedCode
  }

  const saveAsImage = () => {
    let PHeight, PWidth;
    if(!popupWidth || popupWidth == "" || popupWidth == 0){
      console.log(window.screen.width)
      PWidth = window.screen.width
    }
    else PWidth = popupWidth
    if(!popupHeight || popupHeight == "" || popupHeight == 0){
      console.log(window.screen.height)
      PHeight = window.screen.height
    }
    else PHeight = popupHeight
    if(library != "three"){
      const tokenData = `window.tokenData={"tokenId": "0", "hash": "${hash}", "hashes": ["${hash}"], "minter": "${minter}"};`;
      const wrappedCode = `<html><head>${libraryScript}</head><body style="margin: 0px;display: flex;justify-content: center;"><script>${tokenData}${code}</script></body></html>`;
      const iframe = document.createElement('iframe');
      iframe.style.width = PWidth + "px"
      iframe.style.height = PHeight + "px"
      iframe.srcdoc = wrappedCode
      document.body.appendChild(iframe)
      iframe.addEventListener('load', function() {
        // use html-to-image to capture the iframe as an image
        setTimeout(()=>{
          htmlToImage.toPng(iframe.contentDocument.body)
          .then(function (dataUrl) {
            const link = document.createElement('a');
            link.download = `${projectId}-${hash}.png`;
            link.href = dataUrl;
            link.click();
            document.body.removeChild(iframe);
          })
          .catch(function (error) {
            console.error('oops, something went wrong!', error);
            document.body.removeChild(iframe);
          });
        }, 3000)
      });
    }
  
    else{
      console.log("this is three js image")
    }
  }

  const likeNft = () => {

  }
  return (
    <div className="NewExpTab">
      <p className="txtCenter name">Enjoy with new Art Block NFT experience</p>
      <p className="collection txtCenter">With just one click, you can see new Art Blocks like you've never seen before.</p>
      <EditorCommands 
        updateHash = {updateHash} 
        hash = {hash} 
        handleRun={handleRun}
        setPopupWinHeight = {setPopupWinHeight}
        setPopupWinWidth = {setPopupWinWidth}
        saveAsImage = {saveAsImage}
        showPopup = {showPopup}
        likeNft = {likeNft}
      />
      <div className="newexp_container">
        <div style={{width : '40%', textAlign : "left", fontSize : "18px"}}>
          <CodeMirror
            value={code}
            height="800px"
            theme={dracula}
            extensions={[...(wordWrap ? [EditorView.lineWrapping] : []), javascript()]}
            onChange={(code) => setCode(code)}
          />
        </div>
        <div style={{width : '60%'}}>
          <iframe
            title="ArtBlock"
            scrolling="no"
            style={{ overflow: "hidden", width : '100%', height : '100%'}}
            className="overflow-hidden w-full h-full col-span-3"
            id="canvasIframe"
          />
        </div>
      </div>
    </div>
  );
};
export default NewExpTab;
